<template>
	<v-menu
		ref="menu"
		v-model="menu"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y nudge-top="-4"
		min-width="auto"
	>
		<template #activator="{ on, attrs }">
			<v-text-field
				v-model="value" :dense="dense"
				:label="label.toUpperCase()" :prepend-inner-icon="icon"
				readonly v-bind="attrs"
				v-on="on" outlined rounded color="primary"
				:error-messages="getErrorMessage"
				@change="$emit('input', $event)"
				hide-details="auto"
			/>
		</template>
		<v-date-picker
			:value="value"
			:active-picker.sync="activePicker"
			:max="maxDate"
			min="1950-01-01"
			@change="save"
			@input="$emit('input', $event)"
		/>
	</v-menu>
</template>

<script>
import FormFieldError from "@/mixins/FormFieldError.js";

export default {
	name: "DatePicker",
	mixins: [FormFieldError],
	props: {
		value: {
			required: true
		},
		label: {
			type: String,
			required: true
		},
		name:{
			type: String,
			required: true
		},
		icon: {
			type: String,
			required: true
		},
		dense: {
			type: Boolean,
			default: false
		},
		picker: {
			type: [String, null],
			default: null
		},
		errors: {
			type: Object,
			required: false,
			default: () => {}
		},
	},
	data: () => ({
		activePicker: null,
		menu: false,
	}),
	computed: {
		maxDate() {
			return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
		}
	},
	watch: {
		menu (val) {
			val && setTimeout(() => {
				this.activePicker = this.picker || "YEAR"
			})
		},
	},
	methods: {
		save(date) {
			this.$refs.menu.save(date)
		},
	},
}
</script>
